<template>
    <div class="base-container">
        <div class="main-wrapper bg-white">
            <div class="back" @click="back">返回上级</div>
            <div class="title">
                <a class="title" :href="CaseDetails.originalUrl" @click="toUrl" target="_blank" v-html="CaseDetails.caseName"></a>
            </div>
            <div class="info-list">
                <div class="info-item">
                    <div>案由：</div>
                    <div v-text="CaseDetails.caseReason"></div>
                </div>
                <div class="info-item">
                    <div>法院名称：</div>
                    <div v-text="CaseDetails.court"></div>
                </div>
                <div class="info-item">
                    <div>案号：</div>
                    <div v-text="CaseDetails.caseNo"></div>
                </div>
                <div class="info-item">
                    <div>日期：</div>
                    <div>{{ CaseDetails.judgeDate | judgeDate }}</div>
                </div>
            </div>
            <div class="splite-line"></div>
            <div class="text" v-html="CaseDetails.contentHtml">
            </div>
        </div>

    </div>

</template>

<script>
import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex"; //分析页通用table表格展示页

export default {
    name: "viewAll",
    filters: {
        judgeDate: function (value) {
            if (!value) return ''
            value = value.replace('T', ' ')
            value = value.replace('.', ' ')
            value = value.replace('000+00:00', '')
            return value
        }
    },
    data() {
        return {
            CaseDetails: {}
        }
    },
    created(){
        this.getReport(0,'','')
    },
    destroyed(){
        this.getReport(1,'','')
    },
    mounted() {
        this.CaseDetails = getStore('CaseDetails')

    },
    methods: {
        ...mapMutations(["reducePath", "addPath", "addMenuList", "reduceMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'viewAll',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        toUrl(){
            this.getReport(2,'page','title')
        },
        back() {
            this.getReport(2,'page','backBtn');
            this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            this.reducePath();//删除导航最后一个路由
            var currentMenu = getStore("currentMenu");
            this.$router.push({path: currentMenu});
        },
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 0;
}

.title {
    height: auto;
    line-height: 70px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    max-width: 960px;
    margin: 0 auto;

    a {
        text-decoration: none;
        color: #185BCE;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}

.info-list {
    width: 60%;
    margin: 0 auto;

    .info-item {
        position: relative;
        display: inline-block;
        width: 50%;
        height: 20px;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        margin-bottom: 10px;

        div {
            display: inline-block;
        }

        :nth-child(1) {
            color: #969799;
        }

        :nth-child(2) {
            color: #646566;
        }
    }
}

.splite-line {
    width: 80%;
    margin: 20px auto 0;
    border-bottom: 1px solid #C8C9CC;
}

.text {
    width: 80%;
    word-break: break-all;
    margin: 0 auto;
    height: 100%;
    padding: 30px;

    /deep/ a {
        color: #2c3e50;
        text-decoration: none;
        cursor: text;
        pointer-events: none;
    }

    /*正常的未被访问过的链接*/

    /deep/ a:link {
        text-decoration: none;
    }

    /*已经访问过的链接*/

    /deep/ a:visited {
        text-decoration: none;
    }

    /*鼠标划过(停留)的链接*/

    /deep/ a:hover {
        text-decoration: none;
    }

    /* 正在点击的链接*/

    /deep/ a:active {
        text-decoration: none;
    }

    /deep/ .qcc_law_court {
        width: 100%;
        text-align: center;
        line-height: 25px;
        font-size: 20px;
        font-weight: bold;
    }

    /deep/ .qcc_law_paper_type {
        width: 100%;
        margin-top: 5px;
        text-align: center;
        line-height: 25px;
        font-size: 20px;
        font-weight: bold;
    }

    /deep/ .qcc_law_case_no {
        width: 100%;
        text-align: right;
        font-size: 20px;
    }

    /deep/ .qcc_law_judge_party_label,
    /deep/ .qcc_law_current_identification_label,
    /deep/ .qcc_law_judge_trial_label,
    /deep/ .qcc_law_judge_result_label {
        margin: 10px auto;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        color: black;
        text-align: left;
        text-indent: 10px;
        border-left: 5px solid deepskyblue;
    }

    /deep/ .qcc_law_judge_party,
    /deep/ .qcc_law_judge_trial,
    /deep/ .qcc_law_current_identification,
    /deep/ .qcc_law_judge_result {
        width: 100%;

        span {
            width: 100%;
            display: block;
            text-indent: 15px;
            margin: 5px auto;
        }
    }

    /deep/ .qcc_law_judge_collegiate_bench_label,
    /deep/ .qcc_law_judge_collegiate_bench,
    /deep/ .qcc_law_judge_date_label,
    /deep/ .qcc_law_judge_date,
    /deep/ .qcc_law_judge_recorder_label,
    /deep/ .qcc_law_judge_recorder {
        margin-top: 10px;
        display: block;
        text-align: right;
        font-size: 14px;
    }

}

.back{
    position: absolute;
    top: 20px;
    right: 20px;
    color: #185BCE;
    cursor: pointer;
}
</style>